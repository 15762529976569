/* Nav Bar */
.navBar {
  display: flex;
  align-items: center;
  order: 2;
}
.linksContainer {
  display: flex;
  justify-content: center;
}
a.logoLink {
  margin-top: 10px;
}

/* Mobile */

@media only screen and (max-width: 1100px) {
  .navBar {
    display: none;
  }
}
