/* General */
/* background-color: #2b2e63;  RHINO COLOR */

* {
  font-family: montserrat, open sans, Helvetica, sans-serif;
}

body {
  margin: 0;
}
p {
  text-align: center;
}

/* Media Queries Mobile */

@media only screen and (max-width: 1100px) {
}
