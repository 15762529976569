/* Footer */
.footer {
  width: 100%;
  border: 1px solid black;
  color: white;
  background-color: #2b2e63;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h3.locationHeader {
  letter-spacing: 2px;
  color: white;
}
.city {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  padding: 10px 0px 10px 120px;
  box-sizing: border-box;
  color: black;
}

.cityAreasSlider {
  width: 80%;
  background-color: #f2f2f2;
  margin-bottom: 48px;
  margin-top: 24px;
  pointer-events: none;
}
.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}
.bbblogo {
  width: 50px;
  margin: 10px;
  border: white 4px solid;
  border-radius: 5px;
}
.bbbContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rating {
  color: rgba(248, 206, 30, 1);
  font-size: 24px;
  margin-left: 2px;
}
.footContainerWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  width: 95%;
  align-self: center;
}
.footIconContainer {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}
.iconText {
  color: white;
  font-weight: bold;
  font-size: 13.5px;
}
.googleIcon {
  width: 70px;
}

.footerInstaIcon {
  width: 80px;
}
a {
  display: flex;
  justify-content: center;
}
.footer p {
  font-size: 15px;
}
.phoneIcon {
  margin-right: 24px;
}
.phoneNumberFooter {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  margin-top: 60px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.phoneCall {
  color: white;
  text-decoration: none;
}
blockquote {
  margin: 48px 16px;
  color: rgb(80, 88, 99);
  font-family: "Lato", sans-serif;
}

figcaption {
  font-weight: bold;
  font-style: italic;
  margin-top: 8px;
  text-align: right;
}

small {
  align-self: flex-end;
  margin-right: 2.5%;
  margin-bottom: 10px;
}

/* Mobile */

@media only screen and (max-width: 1100px) {
  .bbblogo {
    width: 40px;
    margin-top: 20px;
  }
  .footer p {
    font-size: 14px;
  }
  h3.locationHeader {
    margin-right: 12px;
    margin-left: 12px;
  }

  .phoneNumberFooter {
    font-size: 20px;
  }

  .city {
    font-size: 10px;
    letter-spacing: 2px;
    white-space: nowrap;
    text-align: center;
    padding: 12px 0px;
  }

  .cityAreasSlider {
    width: 95%;
    background-color: #f2f2f2;
    margin-bottom: 48px;
    margin-top: 24px;
    pointer-events: none;
  }
  small {
    font-size: 11px;
  }
}
