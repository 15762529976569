/* About Page */
.pictureContainer {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.aboutUs {
  margin-top: 70px;
  margin-bottom: 20px;
}
.aboutPic {
  width: 75%;
  object-fit: cover;
  border-radius: 2px;
  max-height: 550px;
  margin-top: 10px;
}
.pictureCaption {
  position: absolute;
  top: 10px;
  color: #2b2e63;
  width: 100%;
  font-size: 25px;
  letter-spacing: 2px;
  text-align: center;
}

.jeff,
.cor {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
}
.jeff {
  color: rgba(238, 143, 11, 1);
}
.cor {
  color: rgba(248, 206, 30, 1);
}

.aboutContentWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
}
.aboutText {
  box-sizing: border-box;
  line-height: 1.75;
  margin: 0;
  padding: 50px 50px;
  text-align: justify;
  font-size: 20px;
  width: 75%;
}

.outerAboutContent {
  border-left: rgba(238, 143, 11, 1) 8px solid;
}
.innerAboutContent {
  border-left: rgba(248, 206, 30, 1) 8px solid;
}
.instaContent {
  display: flex;
  align-items: center;
}

.instaIcon {
  height: 60px;
  margin-left: 10px;
}

/* Mobile */

@media only screen and (max-width: 1100px) {
  .aboutUs {
    margin-top: 50px;
  }
  .pictureCaption {
    font-size: 12px;
    width: 95%;
  }
  img.aboutPic {
    width: 95%;
  }

  .aboutText {
    padding: 25px 30px;
    font-size: 16px;
    line-height: 1.5;
    width: 95%;
  }
  span.jeff,
  span.cor {
    font-size: 20px;
  }
}
