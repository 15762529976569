/* Banner */
.banner {
  height: 70px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 99;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
}

.logo {
  width: 280px;
  height: 70px;
}

a.logoLink {
  display: inline-block;
  order: 1;
}

.quoteButton {
  height: 40px;
  margin-right: 20px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 13px;
  align-self: center;
  cursor: pointer;
  background-color: #2b2e63;
  border: #003087;
  order: 3;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 24px;
  padding-right: 24px;
}
.quoteButton:hover {
  background-color: rgba(63, 66, 109, 1);
}

/* Button Shine */
.quoteButton:after {
  content: "";
  top: 0;
  left: 0;
  transform: translateX(100%);
  width: 90%;
  max-width: 300px;
  height: 100%;
  position: absolute;
  z-index: 1;
  animation: slide 6s infinite;
  animation-delay: 2s;
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(30, 196, 180, 0) 99%,
    rgba(30, 196, 180, 0) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(50%, rgba(255, 255, 255, 0.8)),
    color-stop(99%, rgba(128, 186, 232, 0)),
    color-stop(100%, rgba(125, 185, 232, 0))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(30, 196, 180, 0) 99%,
    rgba(30, 196, 180, 0) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(30, 196, 180, 0) 99%,
    rgba(30, 196, 180, 0) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(30, 196, 180, 0) 99%,
    rgba(30, 196, 180, 0) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(25, 165, 148, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(30, 196, 180, 0) 99%,
    rgba(30, 196, 180, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}
/* Button Shine animation */
@keyframes slide {
  0% {
    transform: translateX(-105%);
    opacity: 0.5;
  }
  20% {
    opacity: 0.5;
  }
  40% {
    transform: translateX(140%);
    opacity: 0.5;
  }
  100% {
    transform: translateX(140%);
    opacity: 0;
  }
}

p.caption {
  font-style: italic;
}

.mobileMenu,
.mobileMenuContainer {
  display: none;
}

a.navLinks {
  padding: 16px;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 18px;
  display: inline-block;
  line-height: 24px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  color: #334246;
  letter-spacing: 0.7px;
}

a.navLinks.active {
  color: rgba(238, 143, 11, 1);
}

a.navLinks:hover {
  color: rgba(248, 206, 30, 1);
}

/* Mobile */

@media only screen and (max-width: 1100px) {
  .logo {
    width: 160px;
    height: 50px;
    margin-top: 0px;
    order: 2;
  }

  .mobileMenu,
  .mobileMenuContainer {
    display: block;
  }
  .banner {
    height: 65px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 99;
    background-color: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 0;
  }

  .quoteButton {
    width: 120px;
    height: 35px;
    font-size: 10px;
    padding: 14px 10px;
    margin-right: 0px;
    box-sizing: border-box;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.25px;
    order: 3;
  }

  .bm-menu {
    /* General sidebar styles */
    background-color: #fff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    left: 0px;
  }

  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    left: 0px;
    top: 0px;
  }
  .bm-burger-button {
    font-size: 37px;
    color: #2b2e63;
    order: 1;
    display: flex;
    align-items: center;
    position: relative;
  }

  .bm-overlay {
    display: block;
  }

  a.navLinks:hover {
    color: rgba(238, 143, 11, 1);
  }
  a.logoLink {
    order: 2;
  }
}
