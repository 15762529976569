/* Testimonials */

.testimonials {
  margin-top: 70px;
  margin-bottom: 100px;
  overflow: hidden;
}
.testimonialHeader {
  background-color: #2b2e63;
  color: #eee;
  padding: 15px;
  box-sizing: border-box;
  margin-top: 0px;
  margin-bottom: 40px;
}
.myTestimonials {
  width: 60%;
  height: 500px;
  margin-bottom: 24px;
}

.testimonialContent {
  width: calc(100% - 12px);
  margin-left: 1px;
  box-sizing: border-box;
  border-radius: 4px;
  line-height: 1.75;
  box-shadow: 0 2px 12px rgb(0 0 0 / 25%);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: calc(100% - 24px);
}
.testimonialContentWrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

blockquote.testimonialQuote {
  margin: 0px 16px;
  color: rgb(80, 88, 99);
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}
blockquote.testimonialQuote::selection {
  background-color: transparent;
}
.testQuotesLeftContainer {
  display: flex;
  align-self: flex-start;
  padding: 30px;
}
.testQuotesRightContainer {
  display: flex;
  align-self: flex-end;
  padding: 30px;
}

.ReviewHeaderOrange {
  height: 24px;
  background-color: rgba(238, 143, 11, 0.8);
  width: 100%;
}
.ReviewHeaderYellow {
  height: 24px;
  background-color: rgba(248, 206, 30, 0.8);
  width: 100%;
}

.testimonials .swiper-button-prev {
  left: -108px;
}

.testimonials .swiper-button-next {
  right: -108px;
}

/* Mobile */

@media only screen and (max-width: 1100px) {
  /* Testimonials */

  .testimonials {
    margin-top: 65px;
  }
  .testimonialHeader {
    padding: 10px;
  }
  .myTestimonials {
    width: 90%;
    height: 450px;
  }

  blockquote.testimonialQuote {
    margin: 0px 5px;
    font-size: 13px;
    line-height: 1.3;
  }
  .testQuotesLeftContainer {
    padding: 5px;
  }
  .testQuotesRightContainer {
    padding: 5px;
  }

  .ReviewHeaderOrange {
    height: 20px;
  }
  .ReviewHeaderYellow {
    height: 20px;
  }

  .testimonials .swiper-button-prev {
    left: -10px;
  }

  .testimonials .swiper-button-next {
    right: -10px;
  }
}
