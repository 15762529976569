/* Main Section */
.mainContent {
  width: 100%;
  margin-top: 150px;
  margin-top: 0;
}
h1 {
  text-transform: uppercase;
  text-align: center;
  margin-top: 10px;
  letter-spacing: 1.5px;
  color: #333;
}
h6 {
  font-size: 16px;
  text-align: center;
  margin: 0 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #333;
}

h3 {
  text-align: center;
  text-transform: uppercase;
  color: #333;
}

h2 {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #333;
}

.description {
  margin-bottom: 20px;
  height: 200px;
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  color: #eee;
  background-color: #2b2e63;
}

.description h1 {
  color: inherit;
}

.description h6 {
  color: inherit;
}

/* Featured Products */

.productContainer {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin: 20px;
  margin-top: 50px;
  background-color: #f2f2f2;
  margin-top: 0px;
}

p.productDescription {
  margin: 0;
  padding: 50px 75px;
  text-align: justify;
  align-self: center;
  font-size: 20px;
  box-sizing: border-box;
  line-height: 1.75;
}

.productPics {
  width: 100%;
}
.carouselContainer {
  width: 55%;
}

/* Why Choose Us */

.whyChooseUsContainer {
  background-color: rgba(240, 245, 250, 0.7);
}
.bubbleValuesContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 56px;
  margin-bottom: 56px;
  flex-direction: row;
}

.bubble {
  border-radius: 100%;
  height: 450px;
  width: 450px;
  display: flex;
  align-items: center;
  padding: 60px;
  line-height: 2;
  box-sizing: border-box;
  flex-direction: column;
  box-shadow: 2px 4px 6px 0px rgb(30 30 30 / 40%);
}
.valueIcon {
  font-size: 80px;
  color: rgba(238, 143, 11, 1);
}
.valueIconYellow {
  font-size: 80px;
  color: rgba(248, 206, 30, 1);
}
.valueWrapper {
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 25px;
  width: 125px;
  height: 125px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

h2.choose {
  padding: 30px;
  color: #eee;
  background-color: #2b2e63;
}

.orange {
  background-color: rgb(238, 143, 11, 0.8);
}

.yellow {
  background-color: rgba(248, 206, 30, 0.8);
}

h3.blue {
  padding: 30px;
  color: #eee;
  background-color: #2b2e63;
}

/* Reviews */

.reviewSection {
  margin-top: 56px;
}

.featuredReviews {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.orangeHighlight {
  color: rgb(238, 143, 11, 1);
  font-weight: bold;
  font-size: 25px;
}

.outerReview {
  width: 32%;
  box-sizing: border-box;
  margin: 0px 20px;
  border-radius: 4px;
  line-height: 1.75;
  box-shadow: 0 2px 12px rgb(0 0 0 / 25%);
  display: flex;
  flex-direction: column;
}

.outerReviewHeader {
  height: 24px;
  background-color: rgba(238, 143, 11, 0.8);
  width: 100%;
}

.outerReviewContent {
  display: flex;
  align-items: center;
  padding: 15px;
  height: 100%;
}

.innerReview {
  width: 32%;
  box-sizing: border-box;
  border-radius: 4px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 12px rgb(0 0 0 / 25%);
}

.innerReviewHeader {
  height: 24px;
  background-color: rgba(248, 206, 30, 0.8);
  width: 100%;
}

.innerReviewContent {
  display: flex;
  align-items: center;
  padding: 15px;
  height: 100%;
}

.buttonContainer {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.moreReviewsButton {
  padding: 10px;
  width: 250px;
  cursor: pointer;
  background-color: transparent;
  border-width: 0px;
  margin-bottom: 40px;
  margin-top: 30px;
  color: #bba;
  font-weight: bold;
  text-decoration: none;
  font-size: 13.5px;
}

.quotesLeftContainer {
  display: flex;
  align-items: flex-start;
  height: 100%;
}

.quotesRightContainer {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.quotes {
  font-size: 48px;
  color: rgba(238, 143, 11);
  justify-self: flex-start;
}

.innerReviewQuotes {
  color: rgba(248, 206, 30);
}

/* 


------------------------

Mobile

-------------------------


*/

@media only screen and (max-width: 1100px) {
  /* Mobile Main Section */
  .description {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .mainContent {
    width: 100%;
  }

  h1 {
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;
    letter-spacing: 1.5px;
    font-size: 25px;
    color: #333;
  }
  h6 {
    font-size: 15px;
    text-align: center;
    margin: 0 10px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #333;
  }

  h2 {
    font-size: 18px;
    color: #333;
  }
  h3 {
    text-align: center;
    text-transform: uppercase;
    color: #333;
    font-size: 14px;
  }

  /* Mobile Featured Products */
  .productContainer {
    flex-direction: column;
    width: 100%;
    margin: 0px;
  }
  div.carouselContainer.product-alt {
    order: 1;
  }
  div.productTextContainer.product-alt {
    order: 2;
  }

  .carouselContainer {
    width: 95%;
    margin: auto;
  }

  p.productDescription {
    margin: 0;
    padding: 25px 30px;
    font-size: 16px;
    line-height: 1.5;
  }

  /* Mobile Why Choose Us*/
  .bubble {
    width: 350px;
    height: 350px;
  }
  .valueIcon {
    font-size: 75px;
  }
  div.bubbleValuesContainer {
    flex-direction: column;
    align-items: center;
  }
  .yellow {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  /* Mobile Reviews */
  .featuredReviews {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .outerReview {
    width: 90%;
    box-sizing: border-box;
    border-width: 10px;
    line-height: 1.75;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: scroll;
    margin: 24px 0px;
  }

  .innerReview {
    width: 90%;
    margin: 24px 0px;
  }

  .innerReviewContent,
  .outerReviewContent {
    align-items: unset;
  }

  .quotes {
    font-size: 24px;
  }

  .quotesRightContainer {
    height: unset;
  }

  .quotesLeftContainer {
    height: unset;
  }
}
