/* Form */
.modalHeaderContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 20px;
}
.modalContent {
  border: #002f872e solid 10px;
}

.modalFormTitle {
  width: 100%;
  margin: 1px;
  letter-spacing: 1.2px;
  margin-bottom: 10px;
  font-size: 22px;
  color: #003087;
  font-weight: bold;
}

.phoneContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: #003087;
}

.phoneNumber {
  margin-left: 10px;
}

.small {
  font-size: 18px;
}
.formSubmit {
  background-color: #6a91d9e6;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 40px;
  letter-spacing: 2px;
  font-weight: bold;
  text-transform: uppercase;
}
label {
  color: #333;
  color: #707070;
  letter-spacing: 0.5px;
}

.modalCloseButton {
  cursor: pointer;
  font-size: 50px;
  color: #ccc;
}
input,
textarea {
  display: block;
  padding: 10px;
  margin: 10px 0px;
  width: 100%;
  box-sizing: border-box;
  border-style: solid;
  border-width: 2px;
  border-color: #002f872e;
  background-color: white;
}
.required {
  color: red;
}
form {
  width: 25%;
  margin-left: auto;
  margin-right: auto;
}

.number {
  margin-top: 5px;
  margin-bottom: 15px;
}

.formOverlayspinner.css-50etie {
  height: 100px;
  width: 100px;
}

.formOverlayspinner.css-50etie svg circle {
  stroke: #003087;
  stroke-width: 5px;
  animation-name: customStrokeAnimation;
  stroke: rgba(248, 206, 30);
}

.formOverlaycontent {
  color: #003087;
}

.formOverlayoverlay.css-df17o1 {
  background-color: rgba(21, 5, 70, 0.05);
}

.modalBlur {
  filter: blur(5px);
}

@keyframes customStrokeAnimation {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke: rgba(248, 206, 30, 0.8);
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
    stroke: rgba(238, 143, 11, 0.8);
  }
}

/* Mobile */

@media only screen and (max-width: 1100px) {
  form {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  .modalFormTitle {
    font-size: 18px;
  }
}
