/* Gallery */
.gallery {
  margin-top: 70px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}
.galleryHeader {
  background-color: #2b2e63;
  padding: 15px;
  color: #eee;
  margin-top: 0px;
}
.gallerySelectorWrapper {
  display: flex;
  justify-content: center;
}
.gallerySelectorContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 40%;
}

.gallerySelector {
  background-color: transparent;
  border: none;
  border: #003087 solid 2px;
  color: #333;
  border-radius: 8px;
  width: 30%;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
  color: #334246;
  letter-spacing: 0.7px;
}

.gallerySelector:hover {
  color: rgba(43, 150, 200, 1);
  border: rgba(43, 150, 200, 1) solid 2px;
}

.gallerySelector.active {
  color: rgba(238, 143, 11, 1);
  font-weight: bold;
  border: rgba(238, 143, 11, 1) solid 2px;
}
.galleryContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.galleryPicContainer {
  width: 40%;
  overflow: hidden;
}

.galleryPic {
  width: 100%;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
.swiper-pagination-fraction {
  color: #cccccc;
  font-size: 12px;
  padding: 2px;
  background-color: rgba(0, 0, 0, 0.4);
  width: 99.9% !important;
  bottom: 3px !important;
  box-sizing: border-box !important;
}

/* Mobile */

@media only screen and (max-width: 1100px) {
  .gallery {
    margin-top: 65px;
  }
  .galleryHeader {
    padding: 10px;
  }

  .gallerySelectorContainer {
    width: 100%;
  }
  .gallerySelector {
    width: 33%;
    padding: 5px 7px;
    margin: 5px;
    font-size: 10px;
    letter-spacing: 0.5px;
  }

  .galleryPicContainer {
    width: 95%;
  }
}
